import React from "react";
import styles from "./Organisation.module.css";

const HQWorkshop = () => {
  return (
    <div className={styles.TableContainer}>
      <table className={styles.membersTable}>
        <thead>
          <tr>
            <th>Unit</th>
            <th>Chairman</th>
            <th>Secretary</th>
            <th>Vice Chairman</th>
            <th>Vice Chairman</th>
            <th>Vice Chairman</th>
            <th>Asstt. Secretary</th>
            <th>Asstt. Secretary</th>
            <th>Asstt. Secretary</th>
            <th>Asstt. Secretary</th>
            <th>Treasurer</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>HQ NWR GLO Jaipur</td>
            <td>Subhash Pareek</td>
            <td>Rajeev Kumar Saran</td>
            <td>Uttam Bathra</td>
            <td>Indrapal Singh Tanwar</td>
            <td>Narayan Singh</td>
            <td>Ashish Meena</td>
            <td>Prem Narayan</td>
            <td>Sonal Mathur</td>
            <td>Nootan Prakash Prajapati</td>
            <td>Sunil Dutt Mathur</td>
          </tr>
          <tr>
            <td></td>
            <td>9001195496</td>
            <td>9001195831</td>
            <td>9414461439</td>
            <td>9829900196</td>
            <td>8448995254</td>
            <td>8441940433</td>
            <td>9001195794</td>
            <td></td>
            <td>9314002841</td>
            <td>9001195073</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Ajmer Carriage Workshop</td>
            <td>Mahendra Singh Godara</td>
            <td>Gajanand Mawar</td>
            <td>Anil Kumar Kulshreshtha</td>
            <td>R.S. Chaturvedi</td>
            <td>Shakti Singh Bagh</td>
            <td>Lakhan Lal Meena</td>
            <td>P.K. Mathur</td>
            <td>Vibhor Mishra</td>
            <td>Thomas Topo</td>
            <td>Shweta Hairis</td>
          </tr>
          <tr>
            <td></td>
            <td>9461566655</td>
            <td>9001296456</td>
            <td>9001194602</td>
            <td>9001194599</td>
            <td>9001194578</td>
            <td>9509126312</td>
            <td>9001194595</td>
            <td>8079036430</td>
            <td>9001194600</td>
            <td></td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Ajmer Electric Workshop</td>
            <td>Babu Lal Meena</td>
            <td>Kamlesh Sharma</td>
            <td>Tushar Krantikar</td>
            <td>Usha Jain</td>
            <td>Vinod Kantiwal</td>
            <td>Deepak Kumar Sharma</td>
            <td>Sanjay Gupta</td>
            <td>Suresh Chand</td>
            <td>Vijay Vaishnav</td>
            <td>Kapil Sharma</td>
          </tr>
          <tr>
            <td></td>
            <td>9460611098</td>
            <td>8003715744</td>
            <td>9413694790</td>
            <td></td>
            <td>9828357176</td>
            <td>6378428864</td>
            <td>8822487779</td>
            <td>7665607167</td>
            <td>9214935427</td>
            <td>9413949400</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Ajmer Loco Workshop</td>
            <td>Rajkishore Sharma</td>
            <td>Jagdish Singh</td>
            <td>Anil Kumar Gour</td>
            <td>Subhash Doi</td>
            <td>Vipin Kannojia</td>
            <td>Dewansh Sharma</td>
            <td>Laxmi</td>
            <td>Naveen Kumar</td>
            <td>Naveen Verma</td>
            <td>Praveen Nambiar</td>
          </tr>
          <tr>
            <td></td>
            <td>9828172030</td>
            <td>9929803799</td>
            <td>8114412224</td>
            <td>7627069400</td>
            <td>7737302108</td>
            <td>9636635800</td>
            <td></td>
            <td>8823969996</td>
            <td>9461348881</td>
            <td>9829738874</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Bikaner Workshop</td>
            <td>Mushtak Ali</td>
            <td>Dinesh Singh</td>
            <td>Manoj Rawat</td>
            <td>Altaf Khan</td>
            <td>Suresh Kumar Choudhary</td>
            <td>Vijay Kumar Shrimali</td>
            <td>Kailash Solanki</td>
            <td>Sunil Kumar Yadav</td>
            <td>Rajkumar Khurav</td>
            <td>Rameshwar Lal</td>
          </tr>
          <tr>
            <td></td>
            <td>7793014531</td>
            <td>9214477741</td>
            <td>9166390609</td>
            <td>8209772393</td>
            <td>9783796547</td>
            <td>9413481206</td>
            <td>9829869436</td>
            <td>9983570942</td>
            <td>9461336596</td>
            <td>7737785196</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Jodhpur Workshop</td>
            <td>Lakhan Singh</td>
            <td>Madan Lal Gurjar</td>
            <td>Heernath Goswami</td>
            <td>Ompuri</td>
            <td>Rakesh Boda</td>
            <td>Nathu Singh Jodha</td>
            <td>Pradeep Makad</td>
            <td>Sayyad Moin Husain</td>
            <td>Vinod Kumar Panwar</td>
            <td>Prem Praksash Bohara</td>
          </tr>
          <tr>
            <td></td>
            <td>9468555257</td>
            <td>9928063067</td>
            <td>7014677318</td>
            <td>9784440704</td>
            <td>9460215643</td>
            <td>9414243170</td>
            <td>9414628613</td>
            <td>9828532553</td>
            <td>9829440668</td>
            <td>9982655111</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default HQWorkshop;

import React from "react";
import styles from "./Organisation.module.css";

const BikanerDivision = () => {
  return (
    <div className={styles.TableContainer}>
      <table className={styles.membersTable}>
        <thead>
          <tr>
            <th>Unit</th>
            <th>Chairman</th>
            <th>Secretary</th>
            <th>Vice Chairman</th>
            <th>Vice Chairman</th>
            <th>Vice Chairman</th>
            <th>Asstt. Secretary</th>
            <th>Asstt. Secretary</th>
            <th>Asstt. Secretary</th>
            <th>Asstt. Secretary</th>
            <th>Treasurer</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Bikaner Division</td>
            <td>Shashi Prakash</td>
            <td>Pramod Kumar Yadav</td>
            <td>Pushkar Chayal</td>
            <td>Ram Hans Meena</td>
            <td>Umesh Verma</td>
            <td>Amit Kumar Sharma</td>
            <td>Devendra Yadav</td>
            <td>Jitendra Choudhary</td>
            <td>Manoj Kumar Bissa</td>
            <td>Anand Mohan Sharma</td>
          </tr>
          <tr>
            <td></td>
            <td>9466135435</td>
            <td>9216082068</td>
            <td>9992280555</td>
            <td>9784734444</td>
            <td>9416643996</td>
            <td>7988282446</td>
            <td>9821444233</td>
            <td>9085570411</td>
            <td>9610775840</td>
            <td>9636741783</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Bikaner-BKN</td>
            <td>Balbeer</td>
            <td>Brajesh Ojha</td>
            <td>Aasu Singh Solanki</td>
            <td>Jitendra Vishwkarma</td>
            <td>Vijay Kumar Meena</td>
            <td>Firoz Khan</td>
            <td>Nand Kishore Bhati</td>
            <td>Rajendra Chandela</td>
            <td>Shashikant Parihar</td>
            <td>Sanjay Kumar Rai</td>
          </tr>
          <tr>
            <td></td>
            <td>9116675273</td>
            <td>9413939828</td>
            <td>9413680547</td>
            <td>9352674999</td>
            <td>7737949170</td>
            <td>9214063981</td>
            <td>9636377364</td>
            <td>9887857748</td>
            <td>8209037911</td>
            <td>8209223654</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Lalgarh (DRM)</td>
            <td>Rajendra Kumar Kahtri</td>
            <td>Ganesh Vashishtha</td>
            <td>Deen Dayal</td>
            <td>Shivanand</td>
            <td>Ambika Dubey</td>
            <td>Bhanwar Singh Bika</td>
            <td>Mangal Singh</td>
            <td>Mukesh Kumar Meena</td>
            <td>Pawan Kumar</td>
            <td>Sanjeev Malik</td>
          </tr>
          <tr>
            <td></td>
            <td>9530468351</td>
            <td>9829708970</td>
            <td>9214483544</td>
            <td>9991300785</td>
            <td></td>
            <td>9413938871</td>
            <td>9001034053</td>
            <td>9001197436</td>
            <td>9782434050</td>
            <td>9983391247</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Bathinda</td>
            <td>Jagsir Singh</td>
            <td>Nardendra Bhardwaj</td>
            <td>Deepak Kumar</td>
            <td>Amit Kumar</td>
            <td>Mukesh Chand Meena</td>
            <td>Amjad Khan</td>
            <td>Kuldeep Singh</td>
            <td>Navdeep Kumar</td>
            <td>Vinod Kumar Mishra</td>
            <td>Ravi Kumar</td>
          </tr>
          <tr>
            <td></td>
            <td>9888115529</td>
            <td>8568062611</td>
            <td>6280781130</td>
            <td>9555425186</td>
            <td>9989494332</td>
            <td>9041125712</td>
            <td>8847630818</td>
            <td>7814576105</td>
            <td>9521096948</td>
            <td>7814455403</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Churu</td>
            <td>Shamsher Yadav</td>
            <td>Vikram Singh Sankhla</td>
            <td>Kulwant Swami</td>
            <td>Naresh Kumar Meena</td>
            <td>Ved Prakash</td>
            <td>Anil Sura</td>
            <td>Kapil Kumar</td>
            <td>Navlesh Kumar</td>
            <td>Pawan Kumar Meena</td>
            <td>Prem Kumar</td>
          </tr>
          <tr>
            <td></td>
            <td>8607635320</td>
            <td>9001032832</td>
            <td>8955279670</td>
            <td>9929557979</td>
            <td>9416287558</td>
            <td>8104895280</td>
            <td>7015760245</td>
            <td>978866545</td>
            <td>7976825521</td>
            <td>8504052999</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Hanumangarh</td>
            <td>Rajendra Singh Siddhu</td>
            <td>Subhash Chand Meena</td>
            <td>Hoshiyar Singh</td>
            <td>Moolchand</td>
            <td>Ramesh Kumar Sharma</td>
            <td>Chandan Kumar</td>
            <td>Prakash Swami</td>
            <td>Sandeep Jakhad</td>
            <td>Sant Lal</td>
            <td>Surinder Saharan</td>
          </tr>
          <tr>
            <td></td>
            <td>9001032946</td>
            <td>9460430279</td>
            <td>9461078009</td>
            <td>9828644630</td>
            <td>9001197911</td>
            <td>8955178801</td>
            <td>9001034154</td>
            <td>9413884428</td>
            <td>9887307319</td>
            <td>9461110072</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Hisar</td>
            <td>Krishna Koushik</td>
            <td>Shashi Prakash</td>
            <td>Kishan Panghal</td>
            <td>Kuldeep Khanna</td>
            <td>Sudhir Kumar Lekra</td>
            <td>Dharmpal Yadav</td>
            <td>Praveen Kumar</td>
            <td>Rajesh Verma</td>
            <td>Vikas Kumar</td>
            <td>Subhash Chand</td>
          </tr>
          <tr>
            <td></td>
            <td>9466816320</td>
            <td>9466135435</td>
            <td>9729053090</td>
            <td>8059686888</td>
            <td>9729595396</td>
            <td>8740025187</td>
            <td>8053632595</td>
            <td>9991202500</td>
            <td>9729595260</td>
            <td>9813831432</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Ratangarh</td>
            <td>Ranjeet Singh Punia</td>
            <td>Ram Singh Shekhawat</td>
            <td>Khetaram Nayak</td>
            <td>Rajkumar Meghwal</td>
            <td>Rakesh Kumar Gurjar</td>
            <td>Akhil Singhal</td>
            <td>Prem Singh</td>
            <td>Ravindra Prasad</td>
            <td>Sanjay Kumar Mali</td>
            <td>Pappu Ram Meena</td>
          </tr>
          <tr>
            <td></td>
            <td>8890456641</td>
            <td>9461478087</td>
            <td>8742860430</td>
            <td>9636144380</td>
            <td>8005753926</td>
            <td>9079026412</td>
            <td>9784694150</td>
            <td>9828939459</td>
            <td>9610416480</td>
            <td>9001197525</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Sri Ganganagar</td>
            <td>Mahendra Kumar</td>
            <td>Devendra Kumar</td>
            <td>Gurusharan Singh</td>
            <td>Sanjeev Kumar Arora</td>
            <td>Vijay Singh</td>
            <td>Haldhar Singh</td>
            <td>Jeetsh Meena</td>
            <td>Pit Ram</td>
            <td>Shivraaj Singh</td>
            <td>Bharat Verma</td>
          </tr>
          <tr>
            <td></td>
            <td>9001197423</td>
            <td>9001197244</td>
            <td>9251158639</td>
            <td>8005937901</td>
            <td>9888814448</td>
            <td>9929393506</td>
            <td>9772340051</td>
            <td>9161508647</td>
            <td>9660536301</td>
            <td>8561871330</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Suratgarh</td>
            <td>Kailash Sharma</td>
            <td>Kanhaiya Lal</td>
            <td>Bheeshm Kumar Yadav</td>
            <td>Ram Bilas Yadav</td>
            <td>Sushil Kumar</td>
            <td>Mukesh Kumar Yadav</td>
            <td>Pankaj Meena</td>
            <td>Sitanjali</td>
            <td>Vacanat</td>
            <td>Kanhaiya Lal</td>
          </tr>
          <tr>
            <td></td>
            <td>9024410485</td>
            <td>9783945127</td>
            <td>8104191540</td>
            <td>9116675414</td>
            <td>9799761166</td>
            <td>9680606469</td>
            <td>7976992100</td>
            <td></td>
            <td></td>
            <td>7976992100</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Sirsa</td>
            <td>Hukam Singh Meena</td>
            <td>Prem Kumar Saini</td>
            <td>Lal Bahadur</td>
            <td>Manish Kumar</td>
            <td>Vipin Kumar</td>
            <td>Deepchand</td>
            <td>Pawan Kumar Yadav</td>
            <td>Ram Singh</td>
            <td>Surendra Kumar</td>
            <td>Sumit Kumar</td>
          </tr>
          <tr>
            <td></td>
            <td>8708807844</td>
            <td>9416490536</td>
            <td>9729595376</td>
            <td>8529947297</td>
            <td>9996064180</td>
            <td>8295576931</td>
            <td>7988258955</td>
            <td>8168897727</td>
            <td>7988851738</td>
            <td>8168794505</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BikanerDivision;

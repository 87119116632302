import React from "react";
import logo from "../../assets/nwreu.png";
import styles from "./Header.module.css";
import {
  FaFacebook,
  FaWhatsapp,
  FaTwitter,
  FaTelegram,
  FaInstagram,
  FaBlogger,
} from "react-icons/fa";

const Header2 = () => {
  return (
    <div className={styles.headerContainer}>
      {/* Left side */}
      <div className={styles.left}>
        <img className={styles.logo} src={logo} alt="Logo" />
        <div className={styles.names}>
          <h1 className={styles.heading}>
            नॉर्थ वेस्टर्न रेलवे एम्पलॉईज यूनियन
          </h1>
          <h3 className={styles.subheading}>
            NORTH WESTERN RAILWAY EMPLOYEES UNION
          </h3>
          <p className={styles.text3}>हर पल हर कदम आपके साथ</p>
        </div>
      </div>

      {/* Right side */}
      <div className={styles.right}>
        <div className={styles.lorem}>
          <p className={styles.text1}>H.Q. Jaipur </p>

          <p className={styles.text2}>
            Reg. Recognised & Affiliated to AIRF, HMS & ITF
          </p>
          <div className={styles.icons}>
            <a
              href="https://www.facebook.com/nwreu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook className={styles.socialIcon} />
            </a>
            <a
              href="https://whatsapp.com/channel/0029Va61caZ2f3EM6Knsdh20"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp className={styles.socialIcon} />
            </a>
            <a
              href="https://twitter.com/NWREU"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter className={styles.socialIcon} />
            </a>
            <a
              href="https://www.instagram.com/nwreu_airf/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className={styles.socialIcon} />
            </a>
            {/* <a
              href="https://www.kooapp.com/profile/nwreu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaKiwiBird className={styles.socialIcon} />
            </a> */}
            <a
              href="https://t.me/NWREU1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTelegram className={styles.socialIcon} />
            </a>

            <a
              href="https://www.blogger.com/blog/posts/7896116502704644626"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaBlogger className={styles.socialIcon} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header2;

import React, { useEffect } from "react";

const GoogleTranslate = () => {
  useEffect(() => {
    // Create script element
    const script = document.createElement("script");
    script.src =
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.async = true;

    // Append script to document head
    document.head.appendChild(script);

    // Event listener for script load error
    script.addEventListener("error", handleError);

    // Clean up: Remove script and event listener
    return () => {
      document.head.removeChild(script);
      script.removeEventListener("error", handleError);
    };
  }, []);

  // Error handler function
  const handleError = (error) => {
    console.error("Error loading Google Translate script:", error);
  };

  // Function to initialize Google Translate element
  window.googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        includedLanguages: "en,hi", // Include English and Hindi only
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };

  return <div id="google_translate_element"></div>;
};

export default GoogleTranslate;

import React from "react";
import styles from "./Organisation.module.css";

const RailwayUnionsTable = () => {
  const data = [
    {
      code: "IR",
      hq: "",
      unionName: "All India Railwaymen's Federation",
      unionCode: "AIRF",
      president: "Dr. N Kannaiah",
      presidentPhone: "9840017788",
      generalSecretary: "Shiv Gopal Mishra",
      generalSecretaryPhone: "9717647594",
      zonalSecretary: "Raman Kumar Sharma OSD/AIRF",
    },
    {
      code: "CR",
      hq: "Mumbai",
      unionName: "National Railway Mazdoor Union",
      unionCode: "NRMU(CR)",
      generalSecretary: "P J Shinde",
    },
    {
      code: "ECoR",
      hq: "Bhubaneshwar",
      unionName: "East Coast Railway Sharmik Union",
      unionCode: "ECoRSU",
      generalSecretary: "B Damodar Rao",
      generalSecretaryPhone: "8978055366",
    },
    {
      code: "ECR",
      hq: "Hajipur",
      unionName: "East Central Railway Karmchari Union",
      unionCode: "ECRKU",
      generalSecretary: "O P Sharma",
      generalSecretaryPhone: "9771445215",
    },
    {
      code: "ER",
      hq: "Kolkata",
      unionName: "Eastern Railwaymen’s Union",
      unionCode: "ERMU",
      president: "Ms Madhupee Das",
    },
    {
      code: "NCR",
      hq: "Allahabad",
      unionName: "North Central Railway Mazdoor Union",
      unionCode: "NCRMU",
      president: "R D Yadav",
      presidentPhone: "9794866812",
      generalSecretary: "Shitla Prasad Srivastava",
      generalSecretaryPhone: "9415284761",
    },
    {
      code: "NER",
      hq: "Gorakhpur",
      unionName: "North East Railway Mazdoor Union",
      unionCode: "NERMU",
      president: "K L Gupta",
      presidentPhone: "9794840079",
      generalSecretary: "Basant Lal Chaturvedi",
      generalSecretaryPhone: "9794840078",
    },
    {
      code: "NFR",
      hq: "Maligaon, Guwahati",
      unionName: "North Frontier Railway Mazdoor Union",
      unionCode: "NFRMU",
      president: "Ashis Biswas",
      presidentPhone: "9957550642",
      generalSecretary: "Pijush Chakraborty",
      generalSecretaryPhone: "9864086288",
    },
    {
      code: "NR",
      hq: "Baroda House, New Delhi",
      unionName: "Northern Railwaymens Union",
      unionCode: "NRMU",
      president: "S K Tyagi",
      presidentPhone: "9717455110",
      generalSecretary: "S G Mishra",
      generalSecretaryPhone: "9717647594",
      zonalSecretary: "S U Shah",
      zonalSecretaryPhone: "9794833117",
    },
    {
      code: "NWR",
      hq: "Jaipur",
      unionName: "North Western Railway Employees Union",
      unionCode: "NWREU",
      president: "Manoj Kr Parihar",
      presidentPhone: "9001894721",
      generalSecretary: "Mukesh Mathur",
      generalSecretaryPhone: "900119569",
      zonalSecretary: "Arun Gupta",
      zonalSecretaryPhone: "9829242480",
    },
    {
      code: "SCR",
      hq: "Secunderabad",
      unionName: "South Central Railway Mazdoor Union",
      unionCode: "SCRMU",
      president: "Ch. Sankara Rao",
      presidentPhone: "9848121831",
      generalSecretary: "Ms R M Lakshami",
    },
    {
      code: "SECR",
      hq: "Bilaspur",
      unionName: "South East Central Railway Sharmik Union",
      unionCode: "SECRSU",
      president: "Manoj Kumar Behra",
      presidentPhone: "7000199905",
      generalSecretary: "Girija Shankar Puri",
      generalSecretaryPhone: "9981994101",
    },
    {
      code: "SER",
      hq: "Garden Reach, Kolkata",
      unionName: "South East Railway Men’s Union",
      unionCode: "SERMU",
      president: "Malay Chand Banarjee",
      presidentPhone: "9875398917",
    },
    {
      code: "SR",
      hq: "Chennai",
      unionName: "Southern Railwaymen’s Union",
      unionCode: "SRMU",
      president: "Dr. N Kannaiah",
      presidentPhone: "9840017788",
      generalSecretary: "G Eswar Lal",
      generalSecretaryPhone: "9444018366",
    },
    {
      code: "SWR",
      hq: "Hubli",
      unionName: "South Western RailwayMazdoor Union",
      unionCode: "SWRMU",
      president: "Dr. A M D'Cruz",
      presidentPhone: "9731665034",
      generalSecretary: "K V Raghvendra",
      generalSecretaryPhone: "9731666625",
    },
    {
      code: "WCR",
      hq: "Jabalpur",
      unionName: "West Central Railway Employees Union",
      unionCode: "WCREU",
      president: "Mukesh Galav",
      presidentPhone: "9001017060",
      generalSecretary: "Ms Champa Verma",
    },
    {
      code: "WR",
      hq: "Churchgate",
      unionName: "Western Railway Employees Union",
      unionCode: "WREU",
      president: "R C Sharma",
      presidentPhone: "9724093069",
      generalSecretary: "J R Bhosale",
      generalSecretaryPhone: "9869914521",
      zonalSecretary: "Nikhil Joshi",
    },
    {
      code: "MRK",
      hq: "Kolkata",
      unionName: "Metro Railway Men’s Union",
      unionCode: "MRMU",
    },
    {
      code: "RDSO",
      hq: "Lucknow",
      unionName: "RDSO Karmchari Sangh",
      unionCode: "RDSOKS",
      generalSecretary: "Vac.",
    },
    {
      code: "BLW",
      hq: "Varanasi",
      unionName: "Diesel Locomotive Works Men’s Union",
      unionCode: "DLWMU",
      generalSecretary: "Dr. Pradeep Kumar Sharma",
      generalSecretaryPhone: "9794275018",
    },
    {
      code: "CLW",
      hq: "Chittaranjan",
      unionName: "C L W Railwaymen’s Union",
      unionCode: "CLWRMU",
      generalSecretary: "S N Singh",
    },
    {
      code: "DLW",
      hq: "Patiala",
      unionName: "DMW Railway Men’s Union",
      unionCode: "DMWRMU",
      generalSecretary: "Karnail Singh",
      generalSecretaryPhone: "9779582955",
    },
    {
      code: "ICF",
      hq: "Chennai",
      unionName: "Integral Coach Factory Labour Union",
      unionCode: "ICFLU",
      generalSecretary: "P Mohan Doss",
      generalSecretaryPhone: "9003012270",
    },
    {
      code: "MCF",
      hq: "Raebareli",
      unionName: "MCFMU",
      generalSecretary: "L N Pathak",
      generalSecretaryPhone: "9450355643",
    },
    {
      code: "RCF",
      hq: "Kapurthala",
      unionName: "Rail Coach Factory Men’s Union",
      unionCode: "RCFMU",
      generalSecretary: "Rajinder Singh",
      generalSecretaryPhone: "9803980322",
    },
    {
      code: "RWF",
      hq: "YNK Bangalore",
      unionName: "Rail Wheel Factory Mazdoor Union",
      unionCode: "RWMFU",
      generalSecretary: "V Dayananda Rao",
      generalSecretaryPhone: "9108501831",
    },
  ];

  return (
    <div className={styles.TableContainer}>
      <table className={styles.membersTable}>
        <thead>
          <tr>
            <th>Code</th>
            <th>HQ</th>
            <th>Union Name</th>
            <th>Union Code</th>
            <th>President</th>
            <th>General Secretary</th>
            <th>Zonal Secretary</th>
          </tr>
        </thead>
        <tbody>
          {data.map((union, index) => (
            <tr
              key={index}
              className={
                index % 2 === 0 ? styles.rowColorEven : styles.rowColorOdd
              }
            >
              <td>{union.code}</td>
              <td>{union.hq}</td>
              <td>{union.unionName}</td>
              <td>{union.unionCode}</td>
              <td>
                <td>{union.president}</td>
                <br></br>
                <td>{union.presidentPhone}</td>
              </td>
              <td>
                {" "}
                <td>{union.generalSecretary}</td>
                <br></br>
                <td>{union.generalSecretaryPhone}</td>
              </td>
              <td>
                {" "}
                <td>{union.zonalSecretary}</td>
                <br></br>
                <td>{union.zonalSecretaryPhone}</td>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RailwayUnionsTable;

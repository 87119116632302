import React, { useState, useEffect } from "react";
import { FaFilePdf } from "react-icons/fa";
import icon from "../../assets/UTicon.jpeg";
import styles from "./UnionTimes.module.css"; // Import CSS module
import axios from "axios"; // Import axios for making HTTP requests

const UnionTimes = () => {
  // State variable to hold union times data
  const [unionTimes, setUnionTimes] = useState([]);

  // Fetch union times data from backend on component mount
  useEffect(() => {
    const fetchUnionTimes = async () => {
      try {
        const response = await axios.get("https://nwreu-backend.vercel.app/ut");
        // Sort the data from latest to oldest
        const sortedData = response.data.sort((a, b) => {
          const dateA = new Date(`${a.month} 1, ${a.year}`);
          const dateB = new Date(`${b.month} 1, ${b.year}`);
          return dateB - dateA;
        });
        setUnionTimes(sortedData); // Set the sorted data
      } catch (error) {
        console.error("Error fetching union times:", error);
      }
    };

    fetchUnionTimes();
  }, []); // Empty dependency array ensures useEffect runs only once on mount

  return (
    <div className={styles["pdf-list"]}>
      {unionTimes.map((pdf, index) => (
        <div key={index} className={styles["pdf-item"]}>
          <img src={icon} className={styles["pdf-icon"]} alt="PDF Icon" />
          <a
            href={pdf.link}
            className={styles["pdf-link"]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {pdf.month + " " + pdf.year}
          </a>
        </div>
      ))}
    </div>
  );
};

export default UnionTimes;

import React, { useEffect, useState } from "react";
import {
  FaFacebook,
  FaWhatsapp,
  FaTwitter,
  FaInstagram,
  FaCommentAlt,
  FaTelegram,
  FaBlogger,
  FaKiwiBird,
} from "react-icons/fa";
import axios from "axios"; // Import Axios

import styles from "./Footer.module.css";

function Footer() {
  const [visitCounter, setVisitCounter] = useState(0);

  useEffect(() => {
    // Function to fetch visit counter
    const fetchVisitCounter = async () => {
      try {
        const response = await axios.get(
          "https://nwreu-backend.vercel.app/count"
        );
        setVisitCounter(response.data.counter); // Assuming API returns { count: <value> }
      } catch (error) {
        console.error("Error fetching visit counter:", error);
      }
    };

    fetchVisitCounter(); // Call the function on component mount
  }, []); // Empty dependency array ensures useEffect runs once on mount

  return (
    <div className={styles.footer} style={{ backgroundColor: "#191919" }}>
      <div className={styles.left}>Visits: {visitCounter}</div>
      <div className={styles.center}>हर पल हर कदम आपके साथ</div>
      <div className={styles.right}>
        <a
          href="https://www.facebook.com/nwreu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook className={styles.socialIcon} />
        </a>
        <a
          href="https://whatsapp.com/channel/0029Va61caZ2f3EM6Knsdh20"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp className={styles.socialIcon} />
        </a>
        <a
          href="https://twitter.com/NWREU"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter className={styles.socialIcon} />
        </a>
        <a
          href="https://www.instagram.com/nwreu_airf/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram className={styles.socialIcon} />
        </a>
        {/* <a
          href="https://www.kooapp.com/profile/nwreu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaKiwiBird className={styles.socialIcon} />
        </a> */}
        <a href="https://t.me/NWREU1" target="_blank" rel="noopener noreferrer">
          <FaTelegram className={styles.socialIcon} />
        </a>
        <a
          href="https://www.blogger.com/blog/posts/7896116502704644626"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaBlogger className={styles.socialIcon} />
        </a>
      </div>
    </div>
  );
}

export default Footer;

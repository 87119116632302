import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./ImpLinks.module.css";

const ImpFormats = () => {
  // State to store the fetched important formats
  const [impFormats, setImpFormats] = useState([]);

  // Fetch data from the backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://nwreu-backend.vercel.app/impformats"
        );
        setImpFormats(response.data);
      } catch (error) {
        console.error("Error fetching important formats:", error);
      }
    };

    fetchData();
  }, []);

  // Create rows with three columns each
  const renderRows = () => {
    const rows = [];
    for (let i = 0; i < impFormats.length; i += 3) {
      rows.push(
        <tr key={i}>
          <td>
            <a
              href={impFormats[i]?.link || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              {impFormats[i]?.heading || ""}
            </a>
          </td>
          <td>
            <a
              href={impFormats[i + 1]?.link || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              {impFormats[i + 1]?.heading || ""}
            </a>
          </td>
          <td>
            <a
              href={impFormats[i + 2]?.link || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              {impFormats[i + 2]?.heading || ""}
            </a>
          </td>
        </tr>
      );
    }
    return rows;
  };

  return (
    <div className={styles.linksContainer}>
      {/* Table section */}
      <div className={styles.tableContainer}>
        <table>
          <tbody>{renderRows()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default ImpFormats;

import axios from "axios";

const incrementCount = async () => {
  try {
    const response = await axios.post(
      "https://nwreu-backend.vercel.app/count/increment"
    );
    return response.data.counter;
  } catch (error) {
    console.error("Error incrementing count:", error);
    throw error;
  }
};

export { incrementCount };

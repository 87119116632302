import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./Nwreu.module.css";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const Nwreu = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://nwreu-backend.vercel.app/pnm-nwreu"
        );

        // Sort the data based on the date in descending order
        const sortedData = response.data.sort((a, b) => {
          const dateA = parseDate(a.date);
          const dateB = parseDate(b.date);
          return dateB - dateA; // Descending order
        });

        setData(sortedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Helper function to parse date strings
  const parseDate = (dateString) => {
    const [day1, day2, month, year] = dateString
      .replace(".", "")
      .split(/[-\s]/);
    const date1 = new Date(`${month} ${day1}, ${year}`);
    const date2 = new Date(`${month} ${day2}, ${year}`);
    return date2 > date1 ? date2 : date1; // Return the later date
  };

  return (
    <div className={styles.TableContainer}>
      <span>
        <h2 className={styles.textColour}>
          महाप्रबंधक स्तरीय स्थाई वार्ता तंत्र - GM Level Permanent Negotiating
          Machinery
        </h2>
      </span>
      <Link to="/AgendaItems">
        <span>
          <h2>GM PNM Agenda Items (Click Here) </h2> <td />
          <FaArrowRight />
        </span>
      </Link>
      <table className={styles.membersTable}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Minutes</th>
            <th>Outset & Reply</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.date}</td>
              <td>
                <a
                  href={item.minutesLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.noDecoration}
                >
                  {item.minutes}
                </a>
              </td>
              <td>
                <a
                  href={item.outsetReplyLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.noDecoration}
                >
                  {item.outsetReply}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Nwreu;

import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./Nwreu.module.css";

const RefAirf = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://nwreu-backend.vercel.app/ref-airf"
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Sort data by date in descending order (newest date on top)
  const sortedData = [...data].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  return (
    <div className={styles.TableContainer}>
      <table className={styles.membersTable}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Subject</th>
            <th>.</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <tr key={index}>
              <td>{new Date(item.date).toLocaleDateString()}</td>
              <td>
                <a
                  href={item.subjectLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  {item.subject}
                </a>
              </td>
              <td>
                {" "}
                <a
                  href={item.subjectLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.replyLink}
                >
                  {item.replyLink ? "." : ""}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RefAirf;

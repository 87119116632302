import React from "react";
import style from "./ImpLinks.module.css";

const linksData = {
  "Employee Services": {
    "RRC CBT Mocktest": {
      website: "https://cbt.iroams.com/cbtMock/",
      app: null,
    },
    "Payment-Employee Elf Service (RESS)": {
      website: "https://aims.indianrailways.gov.in/ress",
      app: "https://play.google.com/store/apps/details?id=cris.org.in.ress",
    },
    HRMS: {
      website: "https://hrms.indianrail.gov.in/HRMS/login",
      app: "https://play.google.com/store/apps/details?id=in.co.org.cris.hrmsMobileApplication.free",
    },
    "APAR Module-HRMS": {
      website: "https://hrms.indianrail.gov.in/IRAPAR",
      app: null,
    },
    "UMID Card": {
      website: "https://umid.digitalir.in/web/",
      app: "https://play.google.com/store/apps/details?id=com.cdac.mconsultancyrailwayhmis",
    },
    "Kavach - Gov.in mail Authentication": {
      website:
        "https://kavach.mail.gov.in/mfid/secureLogin_showSecureLogin.action#!",
      app: "https://play.google.com/store/search?q=kavach+authentication+nic&c=apps",
    },
  },
  Travel: {
    "IRCTC Ticket Booking": {
      website: "https://www.irctc.co.in/nget/train-search",
      app: "https://play.google.com/store/apps/details?id=cris.org.in.prs.ima",
    },
    "PNR Status": {
      website:
        "https://www.indianrail.gov.in/enquiry/PNR/PnrEnquiry.html?locale=en",
      app: null,
    },
    "Seat Availability (Duty Quota)": {
      website:
        "https://www.indianrail.gov.in/enquiry/SEAT/SeatAvailability.html?locale=en",
      app: null,
    },
    "NTES (National Train Enquiry System)": {
      website: "https://enquiry.indianrail.gov.in/mntes/",
      app: "https://play.google.com/store/apps/details?id=cris.icms.ntes&hl=en_IN",
    },
  },
  "Bank & Finance": {
    "The Railway Employee's Co-Operative Bank Limited - Jaipur": {
      website: "https://recbjaipur.com/",
      app: "https://play.google.com/store/apps/details?id=com.vsoft.arya.digitalbanking.in.recbjaipur",
    },
    "JC Bank": {
      website: "http://www.jacksonsociety.com/",
      app: null,
    },
    "Income Tax E Filing": {
      website: "https://eportal.incometax.gov.in/iec/foservices/#/login",
      app: null,
    },
  },
  "Android App Download": {
    "e-Daak": {
      website: "#",
      app: "#",
    },
    Digilocker: {
      website:
        "https://play.google.com/store/apps/details?id=com.digilocker.android",
    },
  },
};

function ImportantLinks() {
  return (
    <div className={style.linksContainer}>
      <h2>Important Links</h2>
      <div className={style.columnsContainer}>
        <div className={style.leftColumn}>
          {["Employee Services", "Bank & Finance"].map((category, index) => (
            <div key={index} className={style.category}>
              <h3>{category}</h3>
              <ul className={style.linksList}>
                {Object.keys(linksData[category]).map((name, index) => (
                  <li key={index} className={style.linkItem}>
                    <a
                      href={linksData[category][name].website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {name}
                    </a>
                    {linksData[category][name].app && (
                      <a
                        href={linksData[category][name].app}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={style.appLink}
                      >
                        <tr></tr> - (App Download)
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className={style.rightColumn}>
          {["Travel", "Android App Download"].map((category, index) => (
            <div key={index} className={style.category}>
              <h3>{category}</h3>
              <ul className={style.linksList}>
                {Object.keys(linksData[category]).map((name, index) => (
                  <li key={index} className={style.linkItem}>
                    <a
                      href={linksData[category][name].website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {name}
                    </a>
                    {linksData[category][name].app && (
                      <a
                        href={linksData[category][name].app}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={style.appLink}
                      >
                        <tr></tr> - (App Download)
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <div className={style.tableContainer}>
        <table>
          <tbody>
            <tr>
              <td>
                <a target="_blank" href="https://indianrailways.gov.in/">
                  Indian Railways
                </a>
              </td>
              <td rowspan="2">
                <strong>Code</strong>
              </td>
              <td rowspan="2">
                <strong>Railway Recruitment Cell</strong>
              </td>
              <td rowspan="2">
                <strong>Divison</strong>
              </td>
            </tr>
            <tr>
              <td>
                <a
                  target="_blank"
                  href="https://indianrailways.gov.in/railwayboard/"
                >
                  Railway Board
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://cr.indianrailways.gov.in/">
                  Central Railway
                </a>
              </td>
              <td>CR</td>
              <td>
                <a target="_blank" href="https://www.rrccr.com/home/home">
                  RRC Mumbai
                </a>
              </td>
              <td>
                <td>Mumbai</td>
                <td>Nagpur</td>
                <td>Bhusawal</td>
                <td>Pune</td>
                <td>Sholapur</td>
              </td>
            </tr>

            <tr>
              <td>
                <a target="_blank" href="https://ecr.indianrailways.gov.in/">
                  East Coast Railway
                </a>
              </td>
              <td>ECoR</td>
              <td>
                <a target="_blank" href="https://www.rrcecr.gov.in/">
                  RRC Bhubaneshwar
                </a>
              </td>
              <td>
                <td>Khurda Road</td>
                <td>Waltair</td>
                <td>Sambhalpur</td>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://ecr.indianrailways.gov.in/">
                  East Central Railway
                </a>
              </td>
              <td>ECR</td>
              <td>
                <a target="_blank" href="https://www.rrcecr.gov.in/">
                  RRC Hajipur
                </a>
              </td>
              <td>
                <td>Danapur</td>
                <td>Mugalsarai</td>
                <td>Dhanbad</td>
                <td>Sonpur</td>
                <td>Samastipur</td>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://er.indianrailways.gov.in/">
                  Eastern Railway
                </a>
              </td>
              <td>ER</td>
              <td>
                <a target="_blank" href="https://139.99.53.236:8443/rrcer/">
                  RRC Kolkata
                </a>
              </td>
              <td>
                <td>Howrah-I</td>
                <td>Howrah-II</td>
                <td>Sealdah</td>
                <td>Malda</td>
                <td>Asansol</td>
                <td>Chitaranjan</td>
              </td>
            </tr>

            <tr>
              <td>
                <a target="_blank" href="https://ncr.indianrailways.gov.in/">
                  North Central Railway
                </a>
              </td>
              <td>NCR</td>
              <td>
                <a target="_blank" href="https://rrcpryj.org/">
                  RRC Allahabad
                </a>
              </td>
              <td>
                <td>Allahabad</td>
                <td>Jhansi</td>
                <td>Agra</td>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://ner.indianrailways.gov.in/">
                  North Eastern Railway
                </a>
              </td>
              <td>NER</td>
              <td>
                <a
                  target="_blank"
                  href="https://www.ner.indianrailways.gov.in/"
                >
                  RRC Gorakhpur
                </a>
              </td>
              <td>
                <td>Izzatnagar</td>
                <td>Lucknow</td>
                <td>Varanasi</td>
                <td>DLW</td>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://nfr.indianrailways.gov.in/">
                  North Frontier Railway
                </a>
              </td>
              <td>NFR</td>
              <td>
                <a target="_blank" href="https://rrcnfr.com/">
                  RRC Guwahati
                </a>
              </td>
              <td>
                <td>Katihar</td>
                <td>Alipurduar</td>
                <td>Rangiya</td>
                <td>Lumding</td>
                <td>Tinsukhia</td>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://nr.indianrailways.gov.in/">
                  Northern Railway
                </a>
              </td>
              <td>NR</td>
              <td>
                <a target="_blank" href="https://www.rrcnr.org/">
                  RRC New Delhi
                </a>
              </td>
              <td>
                <td>Delhi-I</td>
                <td>Delhi-II</td>
                <td>Ambala</td>
                <td>Moradabad</td>
                <td>Lucknow</td>
                <td>Firozpur</td>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://nwr.indianrailways.gov.in/">
                  North Western Railway
                </a>
              </td>
              <td>NWR</td>
              <td>
                <a target="_blank" href="https://rrcjaipur.in/">
                  RRC Jaipur
                </a>
              </td>
              <td>
                <td>Jaipur</td>
                <td>Jodhpur</td>
                <td>Bikaner</td>
                <td>Ajmer</td>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://scr.indianrailways.gov.in/">
                  South Central Railway
                </a>
              </td>
              <td>SCR</td>
              <td>
                <a target="_blank" href="https://scr.indianrailways.gov.in/">
                  RRC Secunderabad
                </a>
              </td>
              <td>
                <td>Secunderabad</td>
                <td>Hyderabad</td>
                <td>Guntakal</td>
                <td>Vijaywada</td>
                <td>Nanded</td>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://secr.indianrailways.gov.in/">
                  South East Central Railway
                </a>
              </td>
              <td>SECR</td>
              <td>
                <a target="_blank" href="https://secr.indianrailways.gov.in/">
                  RRC Bilaspur
                </a>
              </td>
              <td>
                <td>Bilaspur</td>
                <td>Nagpur</td>
                <td>Raipur</td>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://ser.indianrailways.gov.in/">
                  South Eastern Railway
                </a>
              </td>
              <td>SER</td>
              <td>
                <a target="_blank" href="https://www.rrcser.co.in/">
                  RRC Kolkata
                </a>
              </td>
              <td>
                <td>Kharagpur</td>
                <td>Adra</td>
                <td>Chakradharpur</td>
                <td>Ranchi</td>
                <td>Shalimar</td>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://sr.indianrailways.gov.in/">
                  Southern Railway
                </a>
              </td>
              <td>SR</td>
              <td>
                <a target="_blank" href="https://rrcmas.in/">
                  RRC Chennai
                </a>
              </td>
              <td>
                <td>Chennai</td>
                <td>Madurai</td>
                <td>Palghat</td>
                <td>Trichy</td>
                <td>Trivendrum</td>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://swr.indianrailways.gov.in/">
                  South Western Railway
                </a>
              </td>
              <td>SWR</td>
              <td>
                <a target="_blank" href="https://www.rrchubli.in/">
                  RRC Hubli
                </a>
              </td>
              <td>
                <td>Bangalore</td>
                <td>Mysore</td>
                <td>Hubli</td>
                <td>RWF/YNK</td>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://wcr.indianrailways.gov.in/">
                  West Central Railway
                </a>
              </td>
              <td>WCR</td>
              <td>
                <a
                  target="_blank"
                  href="https://www.wcr.indianrailways.gov.in/"
                >
                  RRC Jabalpur
                </a>
              </td>
              <td>
                <td>Jabalpur</td>
                <td>Bhopal</td>
                <td>Kota</td>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://wr.indianrailways.gov.in/">
                  Western Railway
                </a>
              </td>
              <td>WR</td>
              <td>
                <a target="_blank" href="https://www.rrc-wr.com/">
                  RRC Churchgate
                </a>
              </td>
              <td>
                <td>BCT</td>
                <td>Vadodara</td>
                <td>Ahmedabad</td>
                <td>Ratlam</td>
                <td>Rajkot</td>
                <td>Bhavnagar</td>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://mtp.indianrailways.gov.in/">
                  Kolkata Metro
                </a>
              </td>
              <td>MRK</td>
              <td>
                <a target="_blank" href="#">
                  Kolkata
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://rdso.indianrailways.gov.in/">
                  Research Designs & Standards Organisation
                </a>
              </td>
              <td>RDSO</td>
              <td>
                <a target="_blank" href="#">
                  Lucknow
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://blw.indianrailways.gov.in/">
                  Banaras Locomotive Works
                </a>
              </td>
              <td>BLW</td>
              <td>
                <a target="_blank" href="#">
                  Varanasi
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://clw.indianrailways.gov.in/">
                  Chittaranjan Locomotive Works
                </a>
              </td>
              <td>CLW</td>
              <td>
                <a target="_blank" href="#">
                  Chittaranjan
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://plw.indianrailways.gov.in/">
                  Diesel Loco Modernisation Works
                </a>
              </td>
              <td>DLW</td>
              <td>
                <a target="_blank" href="#">
                  Patiala
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://icf.indianrailways.gov.in/">
                  Integral Coach Factory
                </a>
              </td>
              <td>ICF</td>
              <td>
                <a target="_blank" href="#">
                  Chennai
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://mcf.indianrailways.gov.in/">
                  Modern Coach Factory
                </a>
              </td>
              <td>MCF</td>
              <td>
                <a target="_blank" href="#">
                  Raebareli
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://rcf.indianrailways.gov.in/">
                  Rail Coach Factory
                </a>
              </td>
              <td>RCF</td>
              <td>
                <a target="_blank" href="#">
                  Kapurthala
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://rwf.indianrailways.gov.in/">
                  Rail Wheel Factory
                </a>
              </td>
              <td>RWF</td>
              <td>
                <a target="_blank" href="#">
                  YNK Bangalore
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={style.tableContainer}>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Railway Recruitment Board (RRB)</strong>
              </td>
            </tr>
            <tr>
              <td>
                <a href="http://www.rrbahmedabad.gov.in/">RRB Ahemdabad</a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://rrbajmer.gov.in/">
                  RRB Ajmer
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://www.rrbald.gov.in/">
                  RRB Allahabad
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://www.rrbbnc.gov.in/">
                  RRB Bangalore
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://www.rrbbhopal.gov.in/">
                  RRB Bhopal
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://www.rrbbbs.gov.in/">
                  RRB Bhubhaneshwar
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://www.rrbbilaspur.gov.in/">
                  RRB Bilaspur
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://www.rrbcdg.gov.in/">
                  RRB Chandigarh
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://www.rrbchennai.gov.in/">
                  RRB Chennai
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://rrbdelhi.gov.in/">
                  RRB Delhi
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://rrbgkp.gov.in/">
                  RRB Gorakhpur
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://rrbguwahati.gov.in/">
                  RRB Guwahati
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://www.rrbhubli.in/">
                  RRB Hubli
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://www.rrbkolkata.gov.in/">
                  RRB Kolkata
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://www.rrbmalda.gov.in/">
                  RRB Malda
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://www.rrbmumbai.gov.in/">
                  RRB Mumbai
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://www.rrbranchi.gov.in/">
                  RRB Ranchi
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a target="_blank" href="https://www.rrbsecunderabad.gov.in/">
                  RRB Secunderabad
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a
                  target="_blank"
                  href="https://www.rrbthiruvananthapuram.gov.in/"
                >
                  RRB Thiruvananthapuram
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ImportantLinks;

import React, { useState } from "react";
import styles from "./About.module.css";
import emailjs from "emailjs-com";

const About = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    designation: "",
    department: "",
    station: "",
    mobileNo: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email using EmailJS
    emailjs
      .send(
        "service_ntqmw9q", // Enter your EmailJS service ID here
        "email", // Enter your EmailJS template ID here
        formData, // Data to be sent as email parameters
        "TiRoUl1JHqlCgxt2B" // Enter your EmailJS user ID here
      )
      .then(
        (response) => {
          console.log("Email sent successfully:", response.text);
          alert("Form submitted successfully! We'll get back to you soon.");
        },
        (error) => {
          console.error("Email send error:", error.text);
          alert("Oops! Something went wrong. Please try again later.");
        }
      );

    // Clear the form fields after submission
    setFormData({
      name: "",
      email: "",
      designation: "",
      department: "",
      station: "",
      mobileNo: "",
      message: "",
    });
  };

  return (
    <div className={styles.aboutContainer}>
      <h2>About Us</h2>
      <p>
        The North Western Railway Employees Union (NWREU) is a labor union that
        represents employees of the North Western Railway zone in India. It was
        formed in 2002, following the creation of the North Western Railway
        zone. The NWREU works to protect the rights and interests of its
        members, including issues related to pay, allowances, promotions, and
        working conditions. They also undertake social and welfare activities
        for the benefit of railway employees.
      </p>

      <div className={styles.contactDetailsC}>
        <h2>Write to us at:</h2>
        <p
          style={{
            display: "block",
            textAlign: "center",
          }}
        >
          <strong
            style={{
              color: "red",
            }}
          >
            North Western Railway Employees Union.
          </strong>{" "}
          <br />
          <strong>Address 1 : </strong> Railway Station Premises, Jaipur
          Junction Railway Station-302004
          <br />
          <strong>Address 2 : </strong> Room No 27, NWR Head Quarter, Near
          Jawahar Circle, Jaipur-302017
        </p>

        <div className={styles.contactDetails}>
          <div className={styles.contactColumn}>
            <p>
              <strong>BSNL(Fax):</strong> 01412725893
              <br />
              <strong>Railway Phone: </strong> 44758, 25784
              <br />
              <strong>Email:</strong>{" "}
              <a href="mailto:mmnwreu@gmail.com">mmnwreu@gmail.com</a>
              <br />
              <strong>Whatsapp:</strong> <a href="tel:9460963919">9460963919</a>
            </p>
          </div>
          <div className={styles.contactColumn}>
            <p>
              Whatsapp Channel:{" "}
              <a href="https://www.whatsapp.com/channel/0029Va61caZ2f3EM6Knsdh20">
                Whatsapp Channel
              </a>
              <br />
              Face Book:{" "}
              <a href="https://www.facebook.com/nwreu/">
                https://www.facebook.com/nwreu/
              </a>
              <br />X (Twitter):{" "}
              <a href="https://x.com/NWREU">https://x.com/NWREU</a>
              {/* <br />
              Koo:{" "}
              <a href="https://www.kooapp.com/profile/nwreu">
                https://www.kooapp.com/profile/nwreu
              </a> */}
              <br />
              Instagram:{" "}
              <a href="https://www.instagram.com/nwreu_airf/">
                https://www.instagram.com/nwreu_airf/
              </a>
              <br />
              Telegram: <a href="https://t.me/NWREU1">https://t.me/NWREU1</a>
            </p>
          </div>
        </div>
      </div>

      <h2>Contact Us</h2>

      <div className={styles.container}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label className={styles.label}>Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your full name"
              className={styles.input}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>E-mail:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              className={styles.input}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Designation:</label>
            <input
              type="text"
              name="designation"
              value={formData.designation}
              onChange={handleChange}
              placeholder="Enter your designation"
              className={styles.input}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Department:</label>
            <select
              name="department"
              value={formData.department}
              onChange={handleChange}
              className={styles.input}
              required
            >
              <option value="" disabled>
                Select Department
              </option>
              <option value="Accounts">Accounts</option>
              <option value="Commercial">Commercial</option>
              <option value="Construction">Construction</option>
              <option value="Electrical">Electrical</option>
              <option value="Engineering">Engineering</option>
              <option value="Gen.Adm.">Gen.Adm.</option>
              <option value="Mechanical">Mechanical</option>
              <option value="Medical">Medical</option>
              <option value="Personnel">Personnel</option>
              <option value="S&T">S&T</option>
              <option value="Safety">Safety</option>
              <option value="Security (RPF)">Security (RPF)</option>
              <option value="Store">Store</option>
              <option value="TRD">TRD</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Unit:</label>
            <select
              name="station"
              value={formData.station}
              onChange={handleChange}
              className={styles.input}
              required
            >
              <option value="" disabled>
                Select Unit
              </option>
              <option value="Ajmer Division">Ajmer Division</option>
              <option value="Bikaner Division">Bikaner Division</option>
              <option value="Jaipur Division">Jaipur Division</option>
              <option value="Jodhpur Division">Jodhpur Division</option>
              <option value="HQ NWR Jaipur">HQ NWR Jaipur</option>
              <option value="Ajmer Carriage Workshop">
                Ajmer Carriage Workshop
              </option>
              <option value="Ajmer Electric Workshop">
                Ajmer Electric Workshop
              </option>
              <option value="Ajmer Loco Workshop">Ajmer Loco Workshop</option>
              <option value="Bikaner Workshop">Bikaner Workshop</option>
              <option value="Jodhpur Workshop">Jodhpur Workshop</option>
              <option value="Other Zone">Other Zone</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Mobile No:</label>
            <input
              type="text"
              name="mobileNo"
              value={formData.mobileNo}
              onChange={handleChange}
              placeholder="Enter your mobile number"
              className={styles.input}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Message:</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Enter your message"
              className={styles.textarea}
              required
            />
          </div>
          <div className={styles.buttonContainer}>
            <button type="submit" className={styles.button}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default About;
